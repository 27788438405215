@import "mixins"
.phoneInput
  position: relative

  & :global
    .intl-tel-input
      @include flex
      width: 100%

      &.separate-dial-code .flag-container
        padding: 0
        height: 36px

        .arrow
          width: 10px
          height: 5px
          background: var(--icon-arrow) no-repeat

          &.up
            transform: rotate(180deg)

          &:after
            content: none !important

        .selected-flag
          border-radius: var(--base_borderRadius)
          padding: 0 8px
          width: 94px !important
          background: var(--field_bg) !important
          @include flex

          .selected-dial-code
            padding-left: 8px !important
            margin-right: auto
            pointer-events: none
            color: #fff
            font-size: 14px
            line-height: 13px

      .phone-widget
        border: 0
        border-radius: var(--base_borderRadius)
        padding: 6px 8px !important
        outline: none
        font-size: 14px
        color: #fff
        width: calc(100% - 100px)
        height: 36px
        margin-left: 100px !important
        background: var(--field_bg)

        &::placeholder
          color: rgba(var(--primaryColor), 0.7)
          text-transform: uppercase

  &.oneCountry
    :global(.flag-container .selected-flag)
      cursor: default
    :global(.arrow)
      display: none

.error
  composes: error from "components/base/input-field/input-field.module.sass"

.fieldError
  composes: fieldError from "components/base/input-field/input-field.module.sass"
